import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  accountsUrl = environment.ACCOUNTS_URL[localStorage.getEncryptedItem('domain')];
  
  constructor() {}

  ngOnInit() {}

}
