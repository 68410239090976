import { HttpClient } from '@angular/common/http';
import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core'
import { Router } from "@angular/router";
import { environment } from '@env/environment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SettingMenuService {
  public data;
  public val = true;
  @Output() fire: EventEmitter<any> = new EventEmitter();
  @Output() dataChangeObserver: EventEmitter<any> = new EventEmitter();
  apiUrl

  constructor(private router: Router,private httpClient: HttpClient) {
    this.apiUrl = environment.apiUrl[localStorage.getEncryptedItem('domain')];
  }

  change() {
    this.fire.emit(this.val);
    if (this.val = !this.val) {
      
    }
    else {
      
    }

  }

  getEmittedValue() {
    return this.fire;
  }

  setData(data: any) {
    this.data = data;
    this.dataChangeObserver.emit(this.data);
    return this.dataChangeObserver;
  }
  getInvitationCount(data){
    return this.httpClient.post(this.apiUrl+'/invitations-count',data);
  }

} 
