import { Component } from '@angular/core';
import { AppState } from './app.service';
import { CommonService } from '@app/shared/common/common.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { LayoutService } from './core/services';
import { JoyrideService } from 'ngx-joyride';
import { TutorialEventsService } from './core/services/tutorial-events.service';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { environment } from '@env/environment';

declare var $:any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}} <a class="cookie_anchor" [href]="accountsUrl+'privacy-policy'" target="_blank">{{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,
    host: {
    '(body:dblclick)': 'ondblClick($event)',
    '(window:keyup)': 'onKeyPress($event)'
  }
})
export class AppComponent {
  title = 'sa';
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  stepContent: any;
  welcome_title = "Welcome";
  inputOpen: boolean;
  isFirstTime: boolean = false;
  tourData: {};
  showCookieConsent:boolean = false;
  accountsUrl;

  public constructor(private appState : AppState, private commonService : CommonService,private idle: Idle,private layoutService:LayoutService,
    private readonly joyrideService: JoyrideService,private ts: TutorialEventsService,private emitService: EmitService,private versionCheckService:VersionCheckService){
      this.appState.setLocalStorageFunctions();
      const host = window.location.host
      const parts = host.split(".");
      parts.shift(); 
      const result = parts.join(".");
      localStorage.setEncryptedItem('domain',result);
      this.accountsUrl = environment.ACCOUNTS_URL[localStorage.getEncryptedItem('domain')];
      setInterval(() => {
        this.appState.createAccessToken().subscribe(data=>{ }); 
      },600000);
      let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
      // let cookieExist = '{"cookieCreatedTime":"2022-06-21T07:07:13.000Z","userMail":"r.murali@iomarket.in","partyID":"179","userID":50,"accId":50,"netcomId":"41420-0000411602-52"}';
      if (cookieExist) {
        cookieExist =  JSON.parse(cookieExist);
        localStorage.getEncryptedItem('partyID') != cookieExist['partyID'] || localStorage.getEncryptedItem('userID') != cookieExist['userID'] ? localStorage.removeEncryptedItem('userData') : '';
        localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
        localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
        localStorage.setEncryptedItem('userID',cookieExist['userID']);
        localStorage.setEncryptedItem('accId',cookieExist['accId']);
        localStorage.setEncryptedItem('netcomId',cookieExist['netcomId']);
        localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false');     
      }
      // localStorage.setEncryptedItem('netcomId','41420-0000411971-8');
    // let p = String(["admin.companyProfile.*","admin.user.*","admin.billing.*","accounts.profile.*"]);
    let p = String(["gate2b.dashboard.edipartner",
    "gate2b.dashboard.process",
    "gate2b.sales.list",
    "gate2b.sales.list.details",
    "gate2b.sales.edipartner",
    "gate2b.purchases.list.details",
    "gate2b.purchases.list",
    "gate2b.purchases.edipartner",
    "gate2b.connections.list",
    "gate2b.settings.interface.insert",
    "gate2b.settings.interface.list.edit",
    "gate2b.settings.interface.list.delete",
    "gate2b.settings.interface.sftp.edit",
    "gate2b.settings.configuration.insert",
    "gate2b.settings.invitation.insert",
    "gate2b.common.cultures.list",
    "gate2b.common.status.list",
    "gate2b.common.directions.list",
    "gate2b.common.encoding.list",
    "gate2b.common.doctype.list",
    "gate2b.common.docformat.list",
    "gate2b.common.interface.list",
    "gate2b.common.company.list",
    "gate2b.settings.company.interface.list",
    "gate2b.settings.company.partnerConnection.list",
    "gate2b.settings.company.connectionName.list",
    "gate2b.settings.bcConnection.insert",
    "gate2b.settings.*",
    "gate2b.dashboard.*",
    "gate2b.purchases.*",
    "gate2b.sales.*",
    "gate2b.connections.*"]);

    let a = this.commonService.encrypt(p);
    // localStorage.setEncryptedItem('permissions',a); 
    // set idle parameters
    this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      // cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      let currentURL =environment.GATE2B_URL[localStorage.getEncryptedItem('domain')]+'userDashboard';
      let lastActive;
      lastActive = Date.parse(this.commonService.decrypt(this.commonService.getCookie(this.commonService.encrypt(environment.USER_ACTIVE_CHECK))))
      let timeDiff;
      timeDiff = Date.now() - lastActive;
      let minuteDiff = timeDiff / (1000 * 60)
      if(minuteDiff > 30)
      {
        let domain = localStorage.getEncryptedItem('domain');
        localStorage.clear();
        window.location.href = environment.LOGOUT_URL[domain]+encodeURIComponent(this.commonService.encrypt(currentURL))
      }else{
        this.reset();
      }
    });
    
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
    this.emitService.emitPreviosUrl(window.location.href)
    }

    reset() {
      // we'll call this method when we want to start/reset the idle process
      // reset any component state and be sure to call idle.watch()
      this.idle.watch();
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.lastPing = null;
    }

    ngOnInit() {
      $("body").addClass(localStorage.getEncryptedItem('domain').split(".")[0]);
      this.reset();
      this.cookieConsent();
      // let style =  this.commonService.getCookie(this.commonService.encrypt('styleCookie'));
      // console.log(this.commonService.decrypt(style));
      // let styleTheme = this.commonService.decrypt(style)
      // console.log('style',styleTheme);
      // this.layoutService.onSmartSkin(JSON.parse(styleTheme))
      // this.getTutorialData();
      // setTimeout(() => {
      //   let language = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].language: "de";
      //   this.emitService.emitLoginLanguageChange(language);
      // },1000);

      console.log(environment.STYLES[localStorage.getEncryptedItem('domain')]);
      environment.STYLES[localStorage.getEncryptedItem('domain')].forEach(element => {
        document.documentElement.style.setProperty(Object.keys(element)[0], element[Object.keys(element)[0]]);
        // console.log('element',element);
        
      });
      this.ts.cast.subscribe(user=> {
        if( user){
          this.getTutorialData();
        }
      });
      this.versionCheckService.initVersionCheck('version.json');
    }
    getTutorialData(){
      let permissionCookie = this.commonService.getCookie(environment.permissions);
      let permission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
      if( Object.keys(this.tourData = {}).length > 0 ){ return;}
      let formData = new FormData();
      let lanId = this.commonService.getCurrentLanguageCode();
      lanId = lanId ? lanId : 'de';
      formData.append('language',lanId.toUpperCase());
      formData.append('type','tour');
      formData.append('fk_application_version',environment.APPLICATION_VERSION);
      formData.append("partyId", localStorage.getEncryptedItem('partyID'));
      formData.append("accId", localStorage.getEncryptedItem('accId'));
      let object = this.commonService.createObject(formData);
      let token = this.commonService.createToken(object);
      let encryptedToken = this.commonService.encrypt(token);
      let encrData = new FormData();
      encrData.append('encrToken',encryptedToken);
      this.appState.getTourListData(encrData).subscribe( data => {        
        // localStorage.setEncryptedItem('stepContent',JSON.stringify(data["data"]));
        // localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(data["data"])))));
        this.tourData = data["data"]["tourData"];
        localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(this.tourData)))));
        this.stepContent = this.tourData;
        this.appState.setTourData(this.stepContent);
        this.ts.chageData(true);
        this.isFirstTime = true;
      })
    }

    ondblClick(e) {
      let permissionCookie = this.commonService.getCookie(environment.permissions);
      let permission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
      if( !permission.includes('superadmin.info.edit')){
        e.preventDefault();
        e.stopImmediatePropagation();
        return true;
      }
      var target = $(e.target);
      let a = Object.keys(target.parents());
      let b = [];  
      a.forEach( e => b.push(target.parents()[e].className));
      if (target.length > 0 && (target[0].className == 'editInput' || b.includes('editInput'))) {
        let textConteny =  $("span.editInput").text();
        // $("textarea.in").val(textConteny)
        $('.editInput').css('display','none');
        $("textarea.in").css('display','block');
        $('.btn-save').css('display','block');
      }
      else if( target.length > 0 && target[0].className == 'joyride-step__title'){
        let titleText = $("div.joyride-step__title").text();
        if($('.tour-title-input').length == 0){
          $('.joyride-step__title').parent().append('<input type="text" class="form-control tour-title-input input-field" />');
        }
        else{
          $('.tour-title-input').css('display','block')
        }
        
        $('.tour-title-input').val(titleText);
        $('.btn-save').css('display','block');
        $('.joyride-step__title').css('display','none');
      }
    }
    onKeyPress(e){
      
      if(!$("textarea.in").is(":visible") && !$("input.tour-title-input").is(":visible") ){
        if( e.keyCode == 13){
          $('.joyride-step__next-container .joyride-button').click();
        }
        
      }
      if($('.joyride-step__container').is(":visible")){
        if(e.keyCode == 37 || e.keyCode == 39){
          e.stopImmediatePropagation();
          return
        }
      }
    }

    saveContent(){
      let stepId = $("textarea.in").attr("id");
      let moduleName;
      let saveContent;
      Object.keys(this.stepContent).forEach( a => {      
        Object.keys(this.stepContent[a]['stepData'] ).forEach( aa => {
          if( this.stepContent[a]['stepData'][aa].label == stepId){
            moduleName = this.stepContent[a]['stepData'][aa].appmodule;
            saveContent = this.stepContent[moduleName].stepData[stepId];
          }
        })
      })
      let desc,title;
      if( $("input.tour-title-input").is(":visible") ){
        if($("input.tour-title-input").val())
          title = $("input.tour-title-input").val();
        else
          return;
      }
      else{
        title = saveContent.title
      }
      if($("textarea.in").is(":visible")){
        if( $("textarea.in").val() )
          desc = $("textarea.in").val();
        else
          return;
      }
      else{
        desc = saveContent['description'];
      }
      if($("textarea.in").is(":visible") || $("input.tour-title-input").is(":visible")){
          this.inputOpen = false;
          let a = $("textarea.in").val();
          let b = $("input.tour-title-input").val();
          // if( a != "" || b != ""){
            let formData = new FormData();
            let lanId = this.commonService.getCurrentLanguageCode();
            lanId = lanId ? lanId : 'en';
            formData.append('language',lanId.toUpperCase());
            formData.append('id',saveContent.cid);
            formData.append('title',title ? title : saveContent.title);
            formData.append('description',desc ? desc : saveContent['description']);
            formData.append('type','tour');
            formData.append("accId", localStorage.getEncryptedItem('accId'));
            formData.append("partyId",localStorage.getEncryptedItem('partyID'));
            let object = this.commonService.createObject(formData);
            let token = this.commonService.createToken(object);
            let encryptedToken = this.commonService.encrypt(token);
            let encrData = new FormData();
            encrData.append('encrToken',encryptedToken);
            this.appState.updateTour(encrData).subscribe( data => {
              this.ts.editUser(true);
              if( title){
                $('.joyride-step__title').text(title);
                $('.joyride-step__title').css('display','block');
                $('.tour-title-input').css('display','none')
              }
              if( desc){
                $("span.editInput").parseHTML(desc);
                $('.editInput').css('display','block');
                $("textarea.in").css('display','none');
                
              }
              $('.btn-save').css('display','none');
            });
          // }
          
        }
    }
    cookieConsent(){
      // this.showCookieConsent = true;
      // this.companyCookie  = localStorage.getEncryptedItem('companyCookie');
  
        if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
          // alert('true')
          this.showCookieConsent = false;
        
      }else{
        // alert('false')
        this.showCookieConsent = true;
        this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
        // localStorage.setEncryptedItem("cookieConsent","true");
        // this.showCookieConsent = true;
      }
    }
    hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=> { 
        $(".cookie-consent").fadeOut(1500);  
        this.showCookieConsent = false ; 
        // localStorage.setEncryptedItem("cookieConsent","false");
        this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');
      }, delay)
    }
  
    }
}
