import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  apiUrl:string;
  gate2bUrl: string;
  _tourData;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.API_END_POINT[localStorage.getEncryptedItem('domain')];
    this.gate2bUrl = environment.apiUrl[localStorage.getEncryptedItem('domain')];
   }
  getUserImg(accID){
    return this.httpClient.post(this.apiUrl+`users/fetch-image`,accID)
  }
  getCommunications(viewData){
    return this.httpClient.post<any[]>(this.gate2bUrl+'/netcomid-interface/',viewData);
  }
  updateTutorialStatus(data){
    return this.httpClient.post(environment.API_NODE_END_POINT[localStorage.getEncryptedItem('domain')]+"gate2bAPI/updateTutorialStatus",data).pipe(map((response:Response)=>response))

  }
  getPlanData(data){
    return this.httpClient.post(environment.API_END_POINT_ADMIN[localStorage.getEncryptedItem('domain')]+'admin/manageSubscriptions',data);
  }
  getCounts(data){
    return this.httpClient.post(environment.ACCOUNTS_API[localStorage.getEncryptedItem('domain')]+"gate2bAPI/gate2B/counts",data).pipe(map((response:Response)=>response))
  }
  getEBillData(data){
    return this.httpClient.post(environment.XSD_API_HOST[localStorage.getEncryptedItem('domain')]+"getEbillUsedCount",data).pipe(map((response:Response)=>response))
  }
  getTourData(){
    return this._tourData;
  }
  setTourData(data){
    this._tourData = data;
  }
  getNetcomId(data) {
    return this.httpClient.post(environment.XSD_API_HOST[localStorage.getEncryptedItem('domain')]+"getEbillUsedCount",data).pipe(map((response:Response)=>response))
  }
}
