import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  apiUrl: any;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.apiUrl[localStorage.getEncryptedItem('domain')];
  }
  getCounts(data){
    return this.httpClient.post<any[]>(this.apiUrl+'/exists',data);
  }
  getebillCounts(data){
    return this.httpClient.post<any[]>(this.apiUrl+'/exists',data);
  }
}
