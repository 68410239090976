import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CommonService } from '@app/shared/common/common.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AppState } from '@app/app.service';
import { HttpClient } from '@angular/common/http';
import { JoyrideService } from 'ngx-joyride';
import { EmitService } from '@app/ts/services/emit.service';
@Injectable()
export class AuthGuard implements CanActivate {
  routerPermission: any;
  constructor(private router: Router,private appService : AppState,private commonService:CommonService,private http: HttpClient, private joyrideService: JoyrideService,
    private emitService: EmitService ) {
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    let roles = route.data.rights as string;
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    this.routerPermission = this.commonService.decrypt(decodeURIComponent(permissionCookie));
    // if( !this.routerPermission.includes(roles)){
    //   window.location.href = environment.LOGOUT_URL[localStorage.getEncryptedItem('domain')]+encodeURIComponent(this.commonService.encrypt(window.location.href))
    // }
    let cookieExist = this.commonService.getCookie(environment.checkCookie);
    if (cookieExist == "") {
      window.location.href = environment.LOGOUT_URL[localStorage.getEncryptedItem('domain')]+encodeURIComponent(this.commonService.encrypt(window.location.href))
    }
    else{
      let expiredCookie = this.commonService.decrypt(decodeURIComponent(cookieExist))
      if (this.tokenExpired(expiredCookie)) {
        // token expired
        this.appService.createAccessToken().subscribe(data=>{ });
      } 
      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','EDI');
        formData.append('accId',localStorage.getEncryptedItem('accId'));
        formData.append('partyID',localStorage.getEncryptedItem('partyID'));
        let objectUser = this.commonService.createObject(formData);
        let tokenUser = this.commonService.createToken(objectUser);
        let encryptedTokenUser = this.commonService.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        let tempPath = window.location.hash.replace('#/','') == '' ? 'userDashboard' : window.location.hash.replace('#/','');
        this.http.post(environment.ACCOUNTS_API[localStorage.getEncryptedItem('domain')]+'/user-profile',encrDataUser).subscribe(dataFromApi => {
          
        // return this.http.post(environment.ACCOUNTS_API[localStorage.getEncryptedItem('domain')]+'/user-profile',encrDataUser)
        // .pipe(map((dataFromApi) => {
          let user = dataFromApi['data'];
          // if( !user[0].hasPermission || !user[0].isConfirmedParty){
          //   localStorage.clear();
          //   window.location.href = environment.LOGIN_URL[localStorage.getEncryptedItem('domain')];
          // }
          let permissions = String(user[0].permissions.Allow);
          this.commonService.setCookie(environment.permissions,this.commonService.encrypt(permissions),10000,environment.cookieDomain,'/');
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
          localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
          localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
          let arr=[];
          user[0].interConnect.forEach(element => {
            arr.push(element.interconnectid)
          });
          localStorage.setEncryptedItem('interConnectCompany',JSON.stringify(arr));
        
          // localStorage.setEncryptedItem('interConnectCompany',user[0].interConnect.length==0?null:[]);
          let permissionCookie = this.commonService.getCookie(environment.permissions);
          this.routerPermission = this.commonService.decrypt(decodeURIComponent(permissionCookie));
          let userName = user[0].username;
          let companyList = user[0].companyDetails;
          let company = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'));
          let companyName = company[0]['companyname'];
          let companyMode = company[0]['companymode']
          let userInfo = {};
          userInfo["userName"] = userName;
          userInfo["companyName"] = companyName;
          userInfo["companyMode"] = companyMode;
          this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
          setTimeout(() => {
            let language = user[0].language ? user[0].language : 'de';
            this.emitService.emitLoginLanguageChange(language);
          },100)
          if( !this.routerPermission.includes(roles)){
            window.location.href = environment.profile_URL[localStorage.getEncryptedItem('domain')];
            return;
          }
          window.location.href = environment.GATE2B_URL[localStorage.getEncryptedItem('domain')] + tempPath ;
        });
      } 
      else{
        if( !this.routerPermission.includes(roles)){      
            window.location.href = environment.profile_URL[localStorage.getEncryptedItem('domain')];
            return;
          }
        return true;
      }
  }
  }
  
  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }
  
}
